import { Loader, NavigationBar } from '@ftbpro/mm-admin-ui-components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'core/store/user/user.selector';
import { NavigationFilters } from 'core/components/NavigationFilters/NavigationFilters';
import { AppcuesLaunchpad } from 'core/components/AppcuesLaunchpad/AppcuesLaunchpad';
import { shouldLoadAppcues } from 'core/externalScripts/appcues/index';
import { STNLogoVariables } from 'app.styles';
import { navigateToPage } from './core/routing/history';
import { ConfigsProvider } from './core/configs/ConfigsProvider';
import { LazyRoutes } from './core/components/routes/LazyRoutes';
import { getDefaultLocation } from './location.utils';

export const AppContent = ({
  userData,
  logout,
  navigationItems,
  routes,
  isAppDataInitializing = false,
  styles,
  }) => {
  const location = useLocation();
  const isUserDataLoaded = useSelector(userSelector.isUserDataLoaded);
  const showSTNLogo = useSelector(userSelector.getIsSTNUser);
  const logoSrc = showSTNLogo ? ConfigsProvider.STN_LOGO : ConfigsProvider.MENU_LOGO_SRC;

  return (
    isUserDataLoaded
    ? (
      <div css={styles.content}>
        <NavigationBar
          navigationItems={navigationItems}
          user={userData}
          onLogout={logout}
          helpCenterLink={ConfigsProvider.HELP_CENTER_URL}
          locationPath={location.pathname}
          navigationHandler={navigateToPage}
          logoSrc={logoSrc}
          onLogoClick={() => navigateToPage('/')}
          launchpad={shouldLoadAppcues() ? AppcuesLaunchpad : undefined}
          showText={!showSTNLogo}
          variables={showSTNLogo ? STNLogoVariables : {}}
        >
          <NavigationFilters />
        </NavigationBar>
        {
        isAppDataInitializing ? <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} /> : <LazyRoutes routes={routes} defaultPage={getDefaultLocation(navigationItems)} />
      }
      </div>
) : <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
    );
};
