import { ConfigsProvider } from '../configs/ConfigsProvider';

const {
  ADMINISTRATION_SERVICE_API_BASE_URL,
  NEW_ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL,
  ORGANIZATION_SERVICE_API_BASE_URL,
 } = ConfigsProvider;

const USER_PROPERTIES_ENDPOINT = '/properties';
const PRODUCTS_ENDPOINT = '/products';
const ORGANIZATIONS = '/organizations';
const PRODUCT = 'products';
const USER_DETAILS = '/logged-user-details';

export class AdministrationServiceConfigsProvider {
  static getAdministrationServiceProductsEndpoint() {
    return `${ADMINISTRATION_SERVICE_API_BASE_URL}${PRODUCTS_ENDPOINT}`;
  }

  static getTenantOrganizationsEndpoint() {
    return `${NEW_ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL}${ORGANIZATIONS}`;
  }

  static getOrganizationPropertiesEndpoint(organizationId, productId) {
    return `${NEW_ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL}${ORGANIZATIONS}/${organizationId}${USER_PROPERTIES_ENDPOINT}?${PRODUCT}=${productId}`;
  }

  static getPropertiesEndpoint(organizationId) {
    return `${ORGANIZATION_SERVICE_API_BASE_URL}${ORGANIZATIONS}/${organizationId}${USER_PROPERTIES_ENDPOINT}`;
  }

  static getAdministrationServiceUserDetailsEndpoint() {
    return `${ADMINISTRATION_SERVICE_API_BASE_URL}${USER_DETAILS}`;
  }
}
